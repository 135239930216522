<template>
    <v-container>
        <v-row class="text-center">
            <!-- <v-col cols="12">
                
            </v-col> -->
            <v-col cols="12">
                <v-text-field
                v-model="cari"
                label="Search Data Item"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-btn color="success" @click="printdata()" block>Print Report <v-icon>mdi-note-text</v-icon> </v-btn> 
            </v-col>
            <!-- <v-col cols="12" sm="6" md="3" lg="2">
                <v-btn block small @click="dialog1 = true">Add Item</v-btn>
            </v-col> -->
            <v-col cols="12" class="text-left">
                <v-expansion-panels focusable>
                    <!-- <v-expansion-panel v-for="item in filteredItems" :key="item.index" @click="getload2(item.uuid)" > -->
                    <v-expansion-panel v-for="item in filteredItems" :key="item.index" @change="getload3(item.uuid, item.trans_items_uuid)" >
                        <v-expansion-panel-header><b>{{item.nama_barang}}</b> {{ item.value ?? '0' }} &#8486;</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="pt-2">
                                <v-col cols="12" v-if="load3.length === 0">
                                    <v-btn block color="primary" @click="dialog4=true">Update</v-btn>
                                </v-col>
                                <v-col cols="12" v-for="item in load3" :key="item.index" align="center">
                                    <v-img
                                    max-height="150"
                                    max-width="250"
                                    :src="baseurl+'/Uploads/'+item.file_name"
                                    @click="OpenNewtab(baseurl+'/Uploads/'+item.file_name)"
                                    ></v-img>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog
            v-model="dialog4"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                <v-btn
                    icon
                    dark
                    @click="cleardialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title><span class="text-h5">Input Data and Picture</span></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn 
                    @click="button4join()"
                    text>
                        {{defaultaction}}
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <!-- <v-card-title>
                <span class="text-h5">Input Data and Picture</span>
                </v-card-title> -->
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                outlined 
                                name="OHM" 
                                label="OHM" 
                                v-model="form2.value" 
                                type="number"
                                step="any"></v-text-field>
                            
                                <v-textarea
                                outlined
                                name="Description"
                                label="Description"
                                v-model="form2.ket"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" style="display: none;">
                                <v-file-input
                                    v-model="form3files"
                                    ref="fileInput"
                                    type="file"
                                    show-size
                                    accept="image/*"
                                    outlined
                                    @change="handleImageChange"
                                    label="Add Images"
                                    >
                                    <!-- <v-icon>mdi-file-image-outline</v-icon> -->
                                </v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <!-- <v-btn block color="primary">Take Picture</v-btn> -->
                                
                                <v-btn @click="openFileInput" block>
                                    Add Images &nbsp;
                                    <v-icon>mdi-camera-enhance-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" v-for="(item, index) in form3list" :key="item.index">
                                <b>Image {{ index + 1 }}</b> 
                                &nbsp;&nbsp;
                                <v-icon color="green" @click="OpenImage(item)">mdi-eye</v-icon> 
                                &nbsp;&nbsp;
                                <v-icon color="red" @click="RemoveFillForm3(index)">mdi-delete-empty</v-icon>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            </v-dialog>

            <!-- Image Modal Dialog -->
            <v-dialog v-model="dialogimg" max-width="500">
                <v-card>
                <v-img :src="imageUrl" contain v-if="imageUrl"></v-img>
                <v-card-actions>
                    <v-btn @click="closeImage">Close</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Image Modal Dialog -->
            <v-dialog v-model="dialogprints" max-width="500">
                <v-card>
                    <v-card-text>
                        <h2 align="center" class="pa-4">
                            Total data : {{ printload.length }}
                        </h2>
                    </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-col cols="6">
                            <v-btn block @click="dialogprints = false">Close</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block @click="unduhdata()">Print</v-btn>
                        </v-col>
                    </v-row>
                    
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-overlay :value="dialogloading" style="z-index: 999;"></v-overlay>
        <v-dialog
        v-model="dialogloading"
        hide-overlay
        persistent
        width="300"
        style="z-index: 9999;"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Please Waiting for data
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
        
    </v-container>
</template>
<script>
// import FileEdit from 'vue-material-design-icons/FileEdit.vue';
// import Link from 'vue-material-design-icons/Link.vue';
// import DeleteEmpty from 'vue-material-design-icons/DeleteEmpty.vue';
import axios from 'axios';
import jsonDataMIME from '../assets/MIME.json'; // Adjust the path based on your project structure
import imageCompression from "browser-image-compression";


export default {
    name: 'PageOne',
    components: {
        // FileEdit,
        // Link,
        // DeleteEmpty,
    },
    data: () => ({
        lokal: false,
        // baseurl: 'http://127.0.0.1',
        baseurl: 'https://mcdermott.interka.my.id',
        load1:[],
        load2:[],
        load3:[],   
        printload:[],   
        cari:'',
        defaultaction:'Save',
        IsMIME:jsonDataMIME,
        form1:{},
        form1_uuid:null,
        form2:{},
        form2_uuid:null,
        form3:{},
        form3files:null,
        form3list:[],
        form3_uuid:null,
        form3detail:{},
        dialog1:false,
        dialog2:false,
        dialog3:false,
        dialog4:false,
        dialogimg:false,
        imageUrl:null,
        dialogloading:false,
        dialogprints:false,
    }),
    computed:{
        filteredItems() {
            let cari = (this.cari  || '').toLowerCase()
            // Filter items based on the search term
            let x = this.load1.filter(item => 
                item.nama_barang.toLowerCase().includes(cari)
                || (item.value || '').toLowerCase().includes(cari)
                || (item.ket || '').toLowerCase().includes(cari)
            );
            // console.log(x)
            return x;
        },
    },
    mounted(){
        this.getload1()
        // console.log(this.IsMIME)
    },
    methods:{
        pingtest(){
            alert('test')
        },
        OpenNewtab(x){
            window.open(x, '_blank');
        },
        OpenImage(x){
            this.dialogimg = true 
            this.imageUrl = x.urlimg
        },
        closeImage(){
            this.dialogimg = false 
            this.imageUrl = null
        },
        RemoveFillForm3(x){ 
            this.form3list.splice(x, 1)
        },
        openFileInput() {
            // Trigger the click event of the hidden file input
            // this.$refs.fileInput.click();
            this.$refs.fileInput.$refs.input.click();
        },
        async edit1(x){
            let data = {
                uuid:x.uuid,
                ket:x.ket,
                nama_barang:x.nama_barang,
            }
            this.form1 = data
            this.dialog1 = true
            this.defaultaction = 'Update'

            // await this.postload1()
        },
        async edit2(x){
            let data = {
                uuid:x.uuid,
                ket:x.ket,
                value:x.value,
                // items_uuid:this.form1_uuid,
            }
            this.form2 = data
            this.dialog2 = true
            this.defaultaction = 'Update'

            // await this.postload1()
        },
        cleardialog(){
            this.dialog1 = false
            this.dialog2 = false
            this.dialog3 = false
            this.dialog4 = false
            this.form1 = {}
            this.form2 = {}
            this.form3 = {}
            this.form3files = null
            this.form3list = []
            this.defaultaction = 'Save'
        },
        padLeadingZeros(num, size) {
            let s = num+"";
            while (s.length < size) s = "0" + s;
            return s;
        },
        getbulan(){
            this.bulans = [
                {nama:'Januari',bulan:1},
                {nama:'Febuari',bulan:2},
                {nama:'Maret',bulan:3},
                {nama:'April',bulan:4},
                {nama:'Mei',bulan:5},
                {nama:'Juni',bulan:6},
                {nama:'Juli',bulan:7},
                {nama:'Agustus',bulan:8},
                {nama:'September',bulan:9},
                {nama:'Oktober',bulan:10},
                {nama:'November',bulan:11},
                {nama:'Desember',bulan:12},
            ]
        },
        getdayname(x){
            if(x || x === 0){
                // let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
                let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                let dayName = days[x];
                return dayName
            }
        },
        getbulanname(x){
            if(x || x === 0){
                // let bulans = ['Januari', 'Febuari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November','Desember'];
                let bulans = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November','December'];
                let bulanName = bulans[x];
                // console.log(x+'bulan')
                return bulanName
            }
        },
        formatdate(x,format=1){
            // console.log(x)
            if(x || x === 0){
                let currentdate = new Date(x);
                let isdate = '';
                if(format == 1){
                    isdate = this.getdayname(currentdate.getDay()) + ", "+
                    this.padLeadingZeros(currentdate.getDate(),2) + "-"
                    + this.getbulanname(currentdate.getMonth())  + "-"
                    + currentdate.getFullYear();
                }else{
                    isdate = this.padLeadingZeros(currentdate.getDate(),2) + " "
                    + this.getbulanname(currentdate.getMonth())  + " "
                    + currentdate.getFullYear();
                }

                return isdate;
            }
        },
        formattime(x){
            if(x || x === 0){
                let currentdate = new Date(x);
                let hours = currentdate.getHours();
                let minutes = currentdate.getMinutes();
                // Pad single-digit hours and minutes with leading zeros
                let formattedHours = hours.toString().padStart(2, '0');
                let formattedMinutes = minutes.toString().padStart(2, '0');
                // Format the time as "HH:mm" (e.g., 09:30)
                let formattedTime = `${formattedHours}:${formattedMinutes}`;

                return formattedTime;
            }
        },
        async getload1(){
            this.dialogloading = true
            let addstatus = this.lokal ? '' : '/api'
            await axios.get(this.baseurl+addstatus+"/items", {
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                this.load1 = response.data.data
                this.dialogloading = false
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.dialogloading = false
            });
        },
        async getload2(x){
            this.dialogloading = true
            this.form1_uuid = x
            this.load2 = []
            let addstatus = this.lokal ? '' : '/api'
            await axios.get(this.baseurl+addstatus+"/trans-items/parent/"+x, {
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                this.load2 = response.data.data
                this.dialogloading = false
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.dialogloading = false
            });
        },
        async getload3(y,x){
            this.dialogloading = true
            this.form1_uuid = y
            this.form2_uuid = x
            this.load3 = []
            let addstatus = this.lokal ? '' : '/api'
            await axios.get(this.baseurl+addstatus+"/trans-uploads/parent/"+x, {
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                this.load3 = response.data.data
                this.dialogloading = false
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.dialogloading = false
            });
        },
        async postload1(){
            this.dialogloading = true
            let data = this.form1
            let x = data.uuid ? '/update/'+data.uuid : '/create'
            
            // console.log([formData,x])
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/items"+x, data)
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                alert(response.data.message)
                // this.load3 = response.data.data
                this.cleardialog()
                this.getload1()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.cleardialog()
                this.dialogloading = false
            });
        },
        async postload2(){
            this.dialogloading = true
            let data = this.form2
            let x = data.uuid ? '/update/'+data.uuid : '/create'
            data.m_items_uuid = this.form1_uuid
            
            // console.log([formData,x])
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/trans-items"+x, data)
            .then(async response => {
                // this.items = response.data;
                // console.log(response.data)
                // alert(response.data.message)
                // this.load3 = response.data.data
                this.form2_uuid = await response.data.uuid
                // this.cleardialog()
                // this.getload2(this.form1_uuid)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                // this.cleardialog()
                this.dialogloading = false
            });
        },
        async postload3check(){
            let data = this.form3
            if(data.file_name_berkas){
                let x = data.file_name_berkas.type
                let z = await this.IsMIME.filter(itsmime => (itsmime.mime).toLowerCase().includes(x.toLowerCase()))
                this.form3detail = (z.length != 0) ?  z[0] : {}
                if(z.length == 0){
                    alert('Files Not Detect accept format!')
                    this.form3 = {}
                }
                // console.log(z)
            }
        },
        async postload3(){
            // this.dialogloading = true
            let data = this.form3
            // let x = data.file_name_berkas.type
            // let z = await this.IsMIME.filter(itsmime => (itsmime.mime).toLowerCase().includes(x.toLowerCase()))

            const formData = new FormData();
            formData.append('mime', data.mime);
            formData.append('type', data.type);
            formData.append('trans_items_uuid', this.form2_uuid);
            formData.append('file_name_berkas', data.file);
            // formData.append('mime', z[0].mime);
            // formData.append('type', z[0].tag);
            // formData.append('trans_items_uuid', this.form2_uuid);
            // formData.append('file_name_berkas', data.file_name_berkas, 'filename.jpg');
            
            // if(!data.file_name_berkas){
            //     alert('Files not selected!')
            //     // this.dialogVisible = false
            //     return false
            // }
            
            // console.log([formData])
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/trans-uploads/create", formData)
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                console.log(response.data.message)
                // alert(response.data.message)
                // this.load3 = response.data.data
                // this.cleardialog()
                this.getload3(this.form2_uuid)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                // this.cleardialog()
                this.dialogloading = false
            });
        },
        async mode4data(){
            let data = this.form2
            let x = data.uuid ? '/update/'+data.uuid : '/create'
            data.m_items_uuid = this.form1_uuid
            let uuid2 = null;

            if(!data.value || data.value == 0){
                alert("Field is Empty!")
                return false
            }
            if(this.form3list.length == 0){
                alert("Images is Empty!")
                return false
            }
            
            // console.log([formData,x])
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/trans-items"+x, data)
            .then(async response => {
                uuid2 = response.data.data.uuid
                this.form2_uuid = uuid2
                // next upload image
                await this.form3list.forEach( async xx => {
                    let datax = xx
                    const formData = new FormData();
                    formData.append('mime', datax.mime);
                    formData.append('type', datax.type);
                    formData.append('trans_items_uuid', uuid2);
                    formData.append('file_name_berkas', datax.file,'ImagesCompress.'+datax.extension);

                    await axios.post(this.baseurl+addstatus+"/trans-uploads/create", formData)
                    .then(response => {
                        console.log(response.data.message)
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        // this.cleardialog()
                        this.dialogloading = false
                    });
                });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.dialogloading = false
            });
            
        },
        async deleteload1(x){
            this.dialogloading = true
            if(!confirm('will you delete this item?')){
                return false
            }
            // console.log([formData,x])
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/items/delete/"+x)
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                alert(response.data.message)
                // this.load3 = response.data.data
                this.cleardialog()
                this.getload1()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.cleardialog()
                this.dialogloading = false
            });
        },
        async deleteload2(x){
            this.dialogloading = true
            if(!confirm('will you delete this transaction?')){
                return false
            }
            // console.log([formData,x])
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/trans-items/delete/"+x)
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                alert(response.data.message)
                // this.load3 = response.data.data
                this.cleardialog()
                this.getload2(this.form1_uuid)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.cleardialog()
                this.dialogloading = false
            });
        },
        async deleteload3(x){
            this.dialogloading = true
            if(!confirm('will you delete this files?')){
                return false
            }
            // console.log([formData,x])
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/trans-uploads/delete/"+x)
            .then(response => {
                // this.items = response.data;
                // console.log(response.data)
                alert(response.data.message)
                // this.load3 = response.data.data
                this.cleardialog()
                this.getload3(this.form2_uuid)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.cleardialog()
                this.dialogloading = false
            });
        },
        async handleImageChange() {
            // console.log(event)
            // const selectedImage = event.target.files[0];
            this.dialogloading = true
            const selectedImage = this.form3files;

            if (selectedImage) {
                // this.originalImage = await URL.createObjectURL(selectedImage);
                let x = selectedImage.type
                let z = await this.IsMIME.filter(itsmime => (itsmime.mime).toLowerCase().includes(x.toLowerCase()))
                // console.log(z)

                try {
                    const options = {
                        maxSizeMB: 1, // Maximum file size in megabytes
                        maxWidthOrHeight: 800, // Maximum width or height of the image
                        useWebWorker: true, // Use Web Workers for faster compression
                    };

                    const compressedImage = await imageCompression(selectedImage, options);
                    const compressedImageUrl = URL.createObjectURL(compressedImage);

                    // console.log(compressedImageUrl)
                    
                    await this.form3list.push({urlimg:compressedImageUrl,file:compressedImage,mime:z[0].mime,type:z[0].tag,extension:z[0].ext})
                    // this.form3.file_name_berkas = compressedImageUrl;
                    this.form3files = null
                    this.dialogloading = false
                } catch (error) {
                    this.dialogloading = false
                    console.error("Error compressing image:", error);
                }
            }
            this.dialogloading = false
        },
        async button4join(){
            let data = this.form2
            if(!data.value || data.value == 0){
                alert("Field is Empty!")
                return false
            }
            if(this.form3list.length == 0){
                alert("Images is Empty!")
                return false
            }

            this.dialogloading = true
            await this.mode4data()
            await this.getload1()
            await this.getload3(this.form1_uuid,this.form2_uuid)
            // console.log([this.form2,this.form2_uuid,this.form3list])
            await this.cleardialog()
            
            this.dialogloading = false
        },
        async printdata(){
            this.dialogprints = true
            this.dialogloading = true
            let x = this.load1

            if(x.length === 0){
                alert('data is empty!')
                return false
            }
            // console.log(x)
            this.printload = []
            let t = 1
            x.forEach(async i => {
                let data = {}
                data.namabarang = i.nama_barang
                data.value = i.value ?? 0

                let allimg = []
                if(i.trans_items_uuid){
                    let addstatus = this.lokal ? '' : '/api'
                    await axios.get(this.baseurl+addstatus+"/trans-uploads/parent/"+i.trans_items_uuid, {
                        headers: {
                            'Accept': 'application/json',
                        },
                    })
                    .then(response => {
                        allimg = response.data.data
                    })
                }
                data.image1 = (allimg[0]) ? allimg[0].file_name : null 
                data.image2 = (allimg[1]) ? allimg[1].file_name : null
                data.index = t++
                // alldata.push(data)
                this.printload.push(data)
                // console.log(tojson)
            });

            this.dialogloading = false
        },
        async unduhdata(){
            // this.dialogprints = true
            this.dialogloading = true

            console.log(this.printload)
            const formData = new FormData();
            formData.append('print', JSON.stringify(this.printload));
            // Make a POST request to the server to retrieve the generated Excel file
            let addstatus = this.lokal ? '' : '/api'
            await axios.post(this.baseurl+addstatus+"/web/doc", formData, { 
                responseType: 'arraybuffer',
            })
            .then(response => {
                try {
                    // Create a Blob from the file content
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

                    // Create a link element
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Report.docx';

                    // Append the link to the document
                    document.body.appendChild(link);

                    // Trigger a click event to initiate the download
                    link.click();

                    // Remove the link from the document
                    document.body.removeChild(link);
                    this.dialogprints = false
                    this.dialogloading = false
                } catch (error) {
                    // this.pdfUrl = null
                    console.error('Error loading DOCX:', error);
                    this.dialogprints = false
                    this.dialogloading = false
                }
            })
            .catch(error => {
                console.error('Error downloading file:', error);
                this.dialogprints = false
                this.dialogloading = false
            });

            this.dialogprints = false
            this.dialogloading = false
        }
    }
}
</script>