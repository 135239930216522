<template>
  <v-app>
    <v-app-bar
      app
      color="grey"
      dark
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-container>
        <!-- Centered text -->
        <!-- <v-toolbar-title class="text-center mx-auto">MCDERMOTT</v-toolbar-title> -->
        <v-col cols="12" align="center">
          <v-img
          contain
          max-width="200"
          :src="require('@/assets/logo3.png')"
          @click="menudefault = 0"
        />
        </v-col>
      </v-container>
    </v-app-bar>

    <v-main v-if="menudefault == 0">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 align="center">PROJECT <br> SELECT TASK BELOW</h2>
          </v-col>

          <v-col cols="12" md="4" class="pa-2">
            <center>
              <v-img
                contain
                max-width="500"
                :src="require('@/assets/banner1.jpeg')"
                @click="menudefault = 1"
                >
              </v-img>
            </center>
          </v-col>

          <v-col cols="12" md="4" class="pa-2">
            <center>
              <v-img
                contain
                max-width="500"
                :src="require('@/assets/banner2.jpeg')"
                @click="menudefault = 2"
                >
              </v-img>
            </center>
          </v-col>

          <v-col cols="12" md="4" class="pa-2">
            <center>
              <v-img
                contain
                max-width="500"
                :src="require('@/assets/banner3.jpeg')"
                @click="menudefault = 3"
                >
              </v-img>
            </center>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-main v-if="menudefault == 1">
      <PageOne/>
    </v-main>

    <v-main v-if="menudefault == 2 || menudefault == 3">
      <v-container>
        <v-col cols="12" align="center">
          <h1>UNDER DEVELOPMENT</h1>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import PageOne from './components/PageOne';

export default {
  name: 'App',

  components: {
    PageOne,
  },

  data: () => ({
    //
    menudefault : 0,
  }),
};
</script>
